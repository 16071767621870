import type { FieldInputsWithoutKey } from "~/layers/ui/modules/VDynamicForm/runtime/types";
import { ServiceLocationType } from "../models";
import type { ConfirmationInput } from "../private/confirm";

export const locationTypeName = (type: ServiceLocationType) => {
  return match(type, {
    [ServiceLocationType.LOCATION]: "Store Location",
    [ServiceLocationType.COLLECT_ADDRESS]: "In Person (Attendee Address)",
    [ServiceLocationType.ADDRESS]: "In Person (Organiser Address)",
    [ServiceLocationType.LINK]: "Meeting Link",
    [ServiceLocationType.COLLECT_PHONE]: "Attendee Phone Number",
    [ServiceLocationType.PHONE]: "Organiser Phone Number",
    default: type,
  });
};

export const defineDialogFormInput = <
  T = {
    title: string;
    subtitle?: string;
    form: ConfirmationInput & { inputs: FieldInputsWithoutKey };
    onSubmit?: { (data: any): void };
  }
>(
  input: T
): T => input;
